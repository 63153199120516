@font-face {
font-family: 'ibmPlex';
src: url(/_next/static/media/c7dc84d05cfe8080-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: 'ibmPlex';
src: url(/_next/static/media/b57725b8c3470143-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
}

@font-face {
font-family: 'ibmPlex';
src: url(/_next/static/media/9617e7fa3dc6e5c9-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
}

@font-face {
font-family: 'ibmPlex';
src: url(/_next/static/media/efb590a61a6e0af3-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
}@font-face {font-family: 'ibmPlex Fallback';src: local("Arial");ascent-override: 101.35%;descent-override: 27.19%;line-gap-override: 0.00%;size-adjust: 101.13%
}.__className_e46600 {font-family: 'ibmPlex', 'ibmPlex Fallback'
}.__variable_e46600 {--font-ibm-plex-sans: 'ibmPlex', 'ibmPlex Fallback'
}

